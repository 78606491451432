@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Alegreya:wght@400;500;700&display=swap');

.othello-text-small {
  font-family: 'fontleroybrown.regular';
  text-transform: uppercase;
  color: gold;
}

.alegreya {
  font-family: 'Alegreya', serif;
}

@font-face {
  font-family: 'FONTLERO'; /*Can be any text*/
  src: local('FONTLERO'), url('./Fonts/FONTLERO.TTF') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'fontleroybrown.regular'; /*Can be any text*/
  src: local('fontleroybrown.regular'),
    url('./Fonts/fontleroybrown.regular.ttf') format('truetype');
  font-display: swap;
}
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif, 'Alegreya', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-display: swap;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  font-display: swap;
}

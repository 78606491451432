.infoPanel-container {
  width: 700px;
}

.image-container {
  height: 500px;
  box-shadow: 0px 0px 2px 2px #888888;
}

.infoPanel-text {
  text-align: center;
}

#test {
  opacity: 90 !important;
}

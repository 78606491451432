@import url('https://fonts.googleapis.com/css2?family=Alegreya:wght@400;500;700&display=swap');
.App {
  text-align: center;
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: medium !important;
  font-family: 'Alegreya', serif !important;
  /* overflow-x: hidden; */
  /* overflow: scroll; */
  /* overscroll-behavior: contain; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.marker {
  /* Set the marker size here */
  width: 2rem;
  height: 2rem;
  border-radius: 2rem;
  /* Set the marker color here */
  background: #aa3300;

  display: inline-block;
  border-bottom-right-radius: 0;
  position: relative;
  transform: rotate(45deg);

  /* optional fanciness */
  border: 1px solid #881100;
}
/* inner circle (optional if you don't need the central dot) */
.marker::before {
  content: '';
  background: white;
  width: 50%;
  height: 50%;
  border-radius: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  /* optional fanciness */
  box-shadow: 0.1rem 0.1rem 0.2rem 0.1rem rgba(0, 0, 0, 0.1);
}
/* shadow (optional if you don't need a shadow) */
.marker::after {
  content: '';
  background: rgba(128, 128, 128, 0.2);
  width: 75%;
  height: 75%;
  border-radius: 100%;
  position: absolute;
  top: 100%;
  left: 100%;
  transform: translate(-50%, -50%) rotate(45deg) scaleX(0.5);
}
@tailwind base;
@tailwind components;
@tailwind utilities;

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
